















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

import { MasterDataItem } from '@/api/braendz';
import { brandStateCategoryColors, brandStateCategoryIcons } from '@/models/BrandStateCategories';

@Component({})
export default class BrandStateCategoryIcon extends Vue {
    // Fields

    // Component Properties
    @Prop({ required: true })
    public value!: MasterDataItem | null;

    @Prop({ required: false, type: Boolean, default: false })
    public baseline!: boolean;

    // Getter and Setter
    public get brandStateCategoryColor(): string | undefined {
        return brandStateCategoryColors.find(i => i.key === this.value?.key)?.color;
    }

    public get brandStateCategoryIcon(): string | undefined {
        return brandStateCategoryIcons.find(i => i.key === this.value?.key)?.icon;
    }

    // Methods
}
