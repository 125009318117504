var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    { attrs: { outlined: "", label: "", color: _vm.color } },
    [
      _c(
        "v-tooltip",
        _vm._b(
          {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "v-icon",
                      _vm._g(
                        _vm._b(
                          { attrs: { left: "", small: "", color: _vm.color } },
                          "v-icon",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.available
                                ? "fa-light fa-check"
                                : "fa-light fa-xmark"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          },
          "v-tooltip",
          _vm.$attrs,
          false
        ),
        [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.available
                  ? _vm.$t("domainChip.domainAvailable")
                  : _vm.$t("domainChip.domainNotAvailable")
              )
            ),
          ]),
        ]
      ),
      _vm._v(" " + _vm._s(_vm.domain) + " "),
      _vm.premium
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  right: "",
                                  "x-small": "",
                                  color: "amber darken-2",
                                },
                              },
                              "v-icon",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_vm._v(" fa-solid fa-star ")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1841765238
              ),
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("domainChip.premium")))])]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }