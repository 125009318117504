var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    _vm._b(
      {
        attrs: {
          "two-line": "",
          value: _vm.value,
          dense: "",
          disabled: _vm.hidden,
          "active-class": "item-active",
        },
      },
      "v-list-item",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-list-item-content",
        [
          _c(
            "v-list-item-title",
            { staticClass: "body-1" },
            [
              !_vm.brandData.brandType ||
              _vm.brandData.brandType.key !== "Figurative"
                ? [_vm._v(" " + _vm._s(_vm.brandData.name) + " ")]
                : _c("v-icon", [_vm._v("$no-text")]),
              _c("brand-state-category-icon", {
                attrs: {
                  value: _vm.brandStateCategory,
                  small: "",
                  right: "",
                  baseline: "",
                },
              }),
            ],
            2
          ),
          _vm.brandData.ownerAddress
            ? _c("v-list-item-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$braendz.getAddressLine(_vm.brandData.ownerAddress)
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c(
            "v-list-item-subtitle",
            [
              _c(
                "v-chip-group",
                { attrs: { "show-arrows": false } },
                [
                  _vm.brandData.brandType
                    ? _c(
                        "tooltip-chip",
                        {
                          attrs: {
                            tooltip: _vm.$t("brand.listItem.brandType"),
                            bottom: "",
                            label: "",
                            outlined: "",
                            small: "",
                            "chip-class": "px-1 mr-1",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.brandData.brandType.description
                                  ? _vm.brandData.brandType.description
                                  : _vm.brandData.brandType.key
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.brandData.registrationNumber
                    ? _c(
                        "tooltip-chip",
                        {
                          attrs: {
                            tooltip: _vm.$t(
                              "brand.listItem.registrationNumber"
                            ),
                            bottom: "",
                            label: "",
                            outlined: "",
                            small: "",
                            "chip-class": "px-1 mr-1",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.brandData.registrationNumber) + " "
                          ),
                        ]
                      )
                    : _vm.brandData.applicationNumber
                    ? _c(
                        "tooltip-chip",
                        {
                          attrs: {
                            tooltip: _vm.$t("brand.listItem.applicationNumber"),
                            bottom: "",
                            label: "",
                            outlined: "",
                            small: "",
                            "chip-class": "px-1 mr-1",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.brandData.applicationNumber) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.brandData.applicationDate
                    ? _c(
                        "tooltip-chip",
                        {
                          attrs: {
                            tooltip: _vm.$t("brand.listItem.applicationDate"),
                            bottom: "",
                            label: "",
                            outlined: "",
                            small: "",
                            "chip-class": "px-1 mr-1",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$d(
                                  new Date(_vm.brandData.applicationDate),
                                  "short"
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "tooltip-chip",
                    {
                      attrs: {
                        tooltip: _vm.$t("brand.listItem.buttonDetails"),
                        "tooltip-color": "primary",
                        bottom: "",
                        label: "",
                        outlined: "",
                        small: "",
                        "chip-class": "px-1 mr-1",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showBrandDetails = true
                        },
                      },
                    },
                    [_c("span", [_vm._v("...")])]
                  ),
                  _vm.canAddToBookmarks
                    ? _c(
                        "tooltip-chip",
                        {
                          attrs: {
                            tooltip: _vm.$t("brand.listItem.bookmarkAdd"),
                            "tooltip-color": "primary",
                            bottom: "",
                            label: "",
                            outlined: "",
                            small: "",
                            "chip-class": "px-1 mr-1",
                            disabled: !_vm.userAccount,
                          },
                          on: { click: _vm.addToBookmarks },
                        },
                        [
                          _c("v-icon", { attrs: { "x-small": "" } }, [
                            _vm._v("fa-solid fa-star"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canRemoveFromBookmarks
                    ? _c(
                        "tooltip-chip",
                        {
                          attrs: {
                            tooltip: _vm.$t("brand.listItem.bookmarkRemove"),
                            "tooltip-color": "primary",
                            bottom: "",
                            label: "",
                            outlined: "",
                            small: "",
                            "chip-class": "px-1 mr-1",
                            disabled: !_vm.userAccount,
                          },
                          on: {
                            click: function ($event) {
                              _vm.deleteBookmarkConfirmationPopupVisible = true
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { "x-small": "" } }, [
                            _vm._v("fa-light fa-star"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list-item-avatar",
        { attrs: { tile: "", "min-width": "150" } },
        [
          _vm.fullBrandLogoUrl
            ? _c("v-img", { attrs: { src: _vm.fullBrandLogoUrl, contain: "" } })
            : _vm._e(),
        ],
        1
      ),
      _c("BrandDetailsPopup", {
        attrs: { id: _vm.brandId },
        model: {
          value: _vm.showBrandDetails,
          callback: function ($$v) {
            _vm.showBrandDetails = $$v
          },
          expression: "showBrandDetails",
        },
      }),
      _vm.brandId
        ? _c("SaveBrandBookmarkPopup", {
            attrs: { brandId: _vm.brandId },
            model: {
              value: _vm.saveBookmarkPopupVisible,
              callback: function ($$v) {
                _vm.saveBookmarkPopupVisible = $$v
              },
              expression: "saveBookmarkPopupVisible",
            },
          })
        : _vm._e(),
      _c("ConfirmationPopup", {
        attrs: {
          yes: "",
          cancel: "",
          question: "",
          title: _vm.$t("brand.tile.bookmarkRemoveConfirmation.title"),
          text: _vm.$t("brand.tile.bookmarkRemoveConfirmation.text"),
        },
        on: { yes: _vm.removeFromBookmarks },
        model: {
          value: _vm.deleteBookmarkConfirmationPopupVisible,
          callback: function ($$v) {
            _vm.deleteBookmarkConfirmationPopupVisible = $$v
          },
          expression: "deleteBookmarkConfirmationPopupVisible",
        },
      }),
      _c("PaywallOverlay", {
        attrs: {
          hasFeature: !_vm.hidden,
          blur: 5,
          requiredLogin: !_vm.userAccount,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }