






















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({
    inheritAttrs: false
})
export default class TooltipChip extends Vue {
    // Fields

    // Component Properties

    @Prop({ required: false })
    public tooltip?: string;

    @Prop({ required: false })
    public disabled?: boolean;

    @Prop({ required: false })
    public bottom?: boolean;

    @Prop({ required: false, type: Boolean, default: true })
    public top?: boolean;

    @Prop({ required: false })
    public left?: boolean;

    @Prop({ required: false })
    public right?: boolean;

    @Prop({ required: false })
    public tooltipColor?: string;

    @Prop({ required: false })
    public chipClass?: string;

    // Getter and Setter
    public get classes() {
      return this.$attrs.class || '';
    }

    // Methods
    public onChipClicked() {
        this.$emit('click');
    }
}
