





































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

import { Brand, BrandIndexItem, BrandQueryResultItem, MasterDataItem } from '@/api/braendz';
import { dummyBrand } from '@/models/DummyBrand';
import { brandStateCategoryColors, brandStateCategoryIcons } from '@/models/BrandStateCategories';

import TruncateTooltip from '@/components/TruncateTooltip.vue';
import BrandStateCategoryIcon from '@/components/Brands/BrandStateCategoryIcon.vue';
import TooltipChip from '@/components/TooltipChip.vue';
import BrandDetailsPopup from '@/components/Brands/BrandDetailsPopup.vue';
import SaveBrandBookmarkPopup from '@/components/BrandBookmarks/SaveBrandBookmarkPopup.vue';
import ConfirmationPopup from '@/components/Popups/ConfirmationPopup.vue';
import PaywallOverlay from '@/components/Features/PaywallOverlay.vue';
import { AccountInfo } from '@azure/msal-browser';

@Component({
    components: {
        TruncateTooltip,
        BrandStateCategoryIcon,
        TooltipChip,
        BrandDetailsPopup,
        SaveBrandBookmarkPopup,
        ConfirmationPopup,
        PaywallOverlay
    },
})
export default class BrandListItem extends Vue {
    // Fields
    public showBrandDetails = false;
    public saveBookmarkPopupVisible = false;
    public deleteBookmarkConfirmationPopupVisible = false;

    // Component Properties
    @Prop({ required: true })
    public value!: BrandQueryResultItem | BrandIndexItem | Brand | null | undefined;

    @Prop({ required: false })
    public canAddToBookmarks?: boolean;

    @Prop({ required: false })
    public canRemoveFromBookmarks?: boolean;

    // Getter and Setter
    public get userAccount(): AccountInfo | null {
        return this.$store.state.userAccount;
    }
    
    public get brandId(): string | null {
        if(!this.value) {
            return null;
        }

        if("brandId" in this.value && this.value.brandId) {
            return this.value.brandId;
        } 
        else if("id" in this.value && this.value.id) {
            return this.value.id;
        }
        else if("indexItem" in this.value && this.value.indexItem?.brandId) {
            return this.value.indexItem.brandId;
        }

        return null;
    }

    public get brandData(): Brand | BrandIndexItem {
        if(!this.value) {
            return dummyBrand;
        }
        else if("indexItem" in this.value) {
            return this.value.indexItem ?? dummyBrand;
        }
        else if("name" in this.value) {
            return this.value;
        }
        return dummyBrand;
    }

    public get fullBrandLogoUrl(): string | null {
        if(this.hidden) return null;
        return this.$braendz.getBrandLogoUrl(this.brandData);
    }

    public get brandStateCategory(): MasterDataItem | null {
        if(!this.value) {
            return null;
        }

        if("indexItem" in this.value && this.value.indexItem?.brandStateCategory) {
            return this.value.indexItem.brandStateCategory;
        }
        else if("brandStateCategory" in this.value && this.value.brandStateCategory) {
            return this.value.brandStateCategory;
        }

        return null;
    }

    public get brandStateCategoryColor(): string | undefined {
        return brandStateCategoryColors.find(i => i.key === this.brandStateCategory?.key)?.color;
    }

    public get brandStateCategoryIcon(): string | undefined {
        return brandStateCategoryIcons.find(i => i.key === this.brandStateCategory?.key)?.icon;
    }

    public get hidden() {
        return !this.value;
    }

    // Methods
    public addToBookmarks(): void {
        if(this.brandId) {
            this.saveBookmarkPopupVisible = true;
        }
    }

    public removeFromBookmarks(): void {
        this.$emit('removeFromBookmarks', this.brandId);
    }

}
