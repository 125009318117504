var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { top: "", disabled: !(_vm.icon || _vm.fab) },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        color: _vm.color,
                        plain: _vm.plain,
                        icon: _vm.icon,
                        fab: _vm.fab,
                      },
                      on: { click: _vm.postAssistantMessage },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm.icon || _vm.fab
                    ? [
                        _c("v-icon", { attrs: { color: _vm.color } }, [
                          _vm._v("fa-light fa-sparkles"),
                        ]),
                      ]
                    : [
                        _c(
                          "v-icon",
                          { attrs: { left: "", color: _vm.color } },
                          [_vm._v("fa-light fa-sparkles")]
                        ),
                        _vm.title
                          ? [_vm._v(" " + _vm._s(_vm.title) + " ")]
                          : [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("explainButton.title")) +
                                  " "
                              ),
                            ],
                      ],
                ],
                2
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm.title
        ? _c("span", [_vm._v(_vm._s(_vm.title))])
        : _c("span", [_vm._v(_vm._s(_vm.$t("explainButton.title")))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }