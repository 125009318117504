var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-main", { ref: "main", staticClass: "bg-light" }, [
    _c(
      "div",
      { staticClass: "d-flex", staticStyle: { "min-height": "100%" } },
      [
        _c(
          "v-container",
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "12" } }, [
                  _c("div", { staticClass: "page-title" }, [
                    _c("h1", [
                      _vm._v(_vm._s(_vm.$t("createBrand.header.title"))),
                    ]),
                    _c("h3", [
                      _vm._v(_vm._s(_vm.$t("createBrand.header.description"))),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step >= 0,
                    expression: "step >= 0",
                  },
                ],
                staticClass: "pt-10",
                attrs: { "data-aos": "fade-down" },
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "fill-height",
                        attrs: { elevation: "1", rounded: "" },
                      },
                      [
                        _c(
                          "v-card-title",
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("fa-light fa-input-text"),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("createBrand.name.title"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-card-subtitle",
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("createBrand.name.subtitle")) +
                                " "
                            ),
                            _c("ExplainButton", {
                              attrs: {
                                icon: true,
                                color: "amber darken-2",
                                chatInstance: _vm.chatInstance,
                                message: _vm.$t(
                                  "createBrand.name.explainMessage"
                                ),
                              },
                              on: {
                                posted: function ($event) {
                                  _vm.chatOpened = true
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          { staticClass: "d-flex flex-column" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                label: _vm.$t(
                                  "createBrand.name.labelTextField"
                                ),
                                disabled: _vm.noName,
                                "hide-details": "",
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name",
                              },
                            }),
                            _c("v-switch", {
                              attrs: {
                                inset: "",
                                flat: "",
                                label: _vm.$t(
                                  "createBrand.name.labelSwitchNoName"
                                ),
                              },
                              on: { change: _vm.setNoName },
                              model: {
                                value: _vm.noName,
                                callback: function ($$v) {
                                  _vm.noName = $$v
                                },
                                expression: "noName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step >= 0,
                    expression: "step >= 0",
                  },
                ],
                attrs: { "data-aos": "fade-down" },
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "fill-height",
                        attrs: { elevation: "1", rounded: "" },
                      },
                      [
                        _c(
                          "v-card-title",
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("fa-light fa-image"),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("createBrand.logo.title"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-card-subtitle",
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("createBrand.logo.subtitle")) +
                                " "
                            ),
                            _c("ExplainButton", {
                              attrs: {
                                icon: true,
                                color: "amber darken-2",
                                chatInstance: _vm.chatInstance,
                                message: _vm.$t(
                                  "createBrand.logo.explainMessage"
                                ),
                              },
                              on: {
                                posted: function ($event) {
                                  _vm.chatOpened = true
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          { staticClass: "d-flex flex-column" },
                          [
                            _c("ImagePicker", {
                              attrs: {
                                title: _vm.$t(
                                  "createBrand.logo.labelLogoPicker"
                                ),
                                "max-preview-height": "300",
                                disabled: _vm.noLogo,
                              },
                              model: {
                                value: _vm.logo,
                                callback: function ($$v) {
                                  _vm.logo = $$v
                                },
                                expression: "logo",
                              },
                            }),
                            _c("v-switch", {
                              attrs: {
                                disabled: _vm.noName,
                                inset: "",
                                flat: "",
                                label: _vm.$t(
                                  "createBrand.logo.labelSwitchNoLogo"
                                ),
                              },
                              on: { change: _vm.setNoLogo },
                              model: {
                                value: _vm.noLogo,
                                callback: function ($$v) {
                                  _vm.noLogo = $$v
                                },
                                expression: "noLogo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step >= 1,
                    expression: "step >= 1",
                  },
                ],
                attrs: { "data-aos": "fade-down" },
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "fill-height",
                        attrs: {
                          elevation: "1",
                          rounded: "",
                          loading: _vm.registrationOfficeCodes.isBusy,
                        },
                      },
                      [
                        _c(
                          "v-card-title",
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("fa-light fa-map-pin"),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("createBrand.offices.title"))
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-card-subtitle",
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("createBrand.offices.subtitle")) +
                                " "
                            ),
                            _c("ExplainButton", {
                              attrs: {
                                icon: true,
                                color: "amber darken-2",
                                chatInstance: _vm.chatInstance,
                                message: _vm.$t(
                                  "createBrand.offices.explainMessage"
                                ),
                              },
                              on: {
                                posted: function ($event) {
                                  _vm.chatOpened = true
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          { staticClass: "d-flex flex-column align-center" },
                          [
                            _c(
                              "v-chip-group",
                              {
                                attrs: { multiple: "", "show-arrows": "" },
                                model: {
                                  value: _vm.selectedRegistrationOfficeCodes,
                                  callback: function ($$v) {
                                    _vm.selectedRegistrationOfficeCodes = $$v
                                  },
                                  expression: "selectedRegistrationOfficeCodes",
                                },
                              },
                              _vm._l(
                                _vm.registrationOfficeCodes.list,
                                function (office) {
                                  return _c(
                                    "v-chip",
                                    {
                                      key: office.key,
                                      attrs: {
                                        label: "",
                                        filter: "",
                                        value: office,
                                      },
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "my-auto mr-2",
                                        attrs: {
                                          src: _vm.$braendz.registrationOfficeCodeFlag(
                                            office.key
                                          ),
                                          contain: "",
                                          "max-height": "24",
                                          "max-width": "24",
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(office.shortDescription) +
                                          " "
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step >= 2,
                    expression: "step >= 2",
                  },
                ],
                attrs: { "data-aos": "fade-down" },
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "fill-height",
                        attrs: { elevation: "1", rounded: "" },
                      },
                      [
                        _c(
                          "v-card-title",
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("fa-light fa-grid-2"),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("createBrand.classification.title")
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-card-subtitle",
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("createBrand.classification.subtitle")
                                ) +
                                " "
                            ),
                            _c("ExplainButton", {
                              attrs: {
                                icon: true,
                                color: "amber darken-2",
                                chatInstance: _vm.chatInstance,
                                message: _vm.$t(
                                  "createBrand.classification.explainMessage"
                                ),
                              },
                              on: {
                                posted: function ($event) {
                                  _vm.chatOpened = true
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-tabs",
                              {
                                attrs: {
                                  "background-color": "transparent",
                                  centered: "",
                                },
                                model: {
                                  value: _vm.selectedClassificationTab,
                                  callback: function ($$v) {
                                    _vm.selectedClassificationTab = $$v
                                  },
                                  expression: "selectedClassificationTab",
                                },
                              },
                              [
                                _c("v-tab", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "createBrand.classification.tabAiSelection.title"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("v-tab", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "createBrand.classification.tabManualSelection.title"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-tabs-items",
                              {
                                staticClass: "my-5",
                                model: {
                                  value: _vm.selectedClassificationTab,
                                  callback: function ($$v) {
                                    _vm.selectedClassificationTab = $$v
                                  },
                                  expression: "selectedClassificationTab",
                                },
                              },
                              [
                                _c(
                                  "v-tab-item",
                                  { staticClass: "pa-1" },
                                  [
                                    _c(
                                      "v-container",
                                      { attrs: { fluid: "" } },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "d-flex flex-column",
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    disabled:
                                                      _vm
                                                        .goodsServicesSemanticQueryResult
                                                        .isBusy,
                                                    loading:
                                                      _vm
                                                        .goodsServicesSemanticQueryResult
                                                        .isBusy,
                                                    outlined: "",
                                                    "hide-details": "",
                                                    label: _vm.$t(
                                                      "createBrand.classification.tabAiSelection.goodsServicesUserDescriptionLabel"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.queryGoodsServicesByVector.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.goodsServicesUserDescription,
                                                    callback: function ($$v) {
                                                      _vm.goodsServicesUserDescription =
                                                        $$v
                                                    },
                                                    expression:
                                                      "goodsServicesUserDescription",
                                                  },
                                                }),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "align-self-center ma-2",
                                                    attrs: {
                                                      plain: "",
                                                      disabled:
                                                        !_vm.goodsServicesUserDescription ||
                                                        _vm
                                                          .goodsServicesSemanticQueryResult
                                                          .isBusy,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.queryGoodsServicesByVector,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { left: "" } },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-magnifying-glass"
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.classification.tabAiSelection.buttonFindGoodsServicesByVector"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.goodsServicesSemanticQueryResult
                                          .object &&
                                        _vm.goodsServicesSemanticQueryResult
                                          .object.items
                                          ? _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-subheader",
                                                      { staticClass: "pa-0" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "createBrand.classification.tabAiSelection.selectGoodsAndServicesLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-chip-group",
                                                      {
                                                        attrs: {
                                                          column: "",
                                                          multiple: "",
                                                          "active-class":
                                                            "primary primary--text",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedGoodsServices,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedGoodsServices =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedGoodsServices",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-expansion-panels",
                                                          {
                                                            attrs: {
                                                              multiple: "",
                                                              flat: "",
                                                              value:
                                                                _vm.goodsServicesSemanticSearchResultOpenedPanels,
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.groupedGoodsServicesSemanticQueryResult,
                                                            function (
                                                              group,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "v-expansion-panel",
                                                                { key: index },
                                                                [
                                                                  group.niceClass
                                                                    ? _c(
                                                                        "v-expansion-panel-header",
                                                                        {
                                                                          staticClass:
                                                                            "pa-0",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item",
                                                                            {
                                                                              staticClass:
                                                                                "px-0",
                                                                              attrs:
                                                                                {
                                                                                  dense:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-1",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "justify-center",
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$braendz.getNiceClassIcon(
                                                                                            group
                                                                                              .niceClass
                                                                                              .key
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-list-item-content",
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-title",
                                                                                    {
                                                                                      staticClass:
                                                                                        "font-weight-medium",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            group
                                                                                              .niceClass
                                                                                              .shortDescription
                                                                                          ) +
                                                                                          " - " +
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "createBrand.classification.niceClass"
                                                                                            )
                                                                                          ) +
                                                                                          " " +
                                                                                          _vm._s(
                                                                                            group
                                                                                              .niceClass
                                                                                              .key
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "v-expansion-panel-content",
                                                                    {
                                                                      staticClass:
                                                                        "px-0",
                                                                    },
                                                                    _vm._l(
                                                                      group.resultItems,
                                                                      function (
                                                                        goodOrServiceResultItem,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "v-chip",
                                                                          {
                                                                            key: index,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "",
                                                                                outlined:
                                                                                  "",
                                                                                filter:
                                                                                  "",
                                                                                value:
                                                                                  goodOrServiceResultItem.indexItem,
                                                                              },
                                                                          },
                                                                          [
                                                                            goodOrServiceResultItem.indexItem
                                                                              ? [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        goodOrServiceResultItem
                                                                                          .indexItem
                                                                                          .description
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              : _vm._e(),
                                                                          ],
                                                                          2
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  [
                                    _c(
                                      "v-container",
                                      { attrs: { fluid: "" } },
                                      [
                                        _vm.groupedSelectedGoodsServices
                                          .length > 0
                                          ? _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-chip-group",
                                                      { attrs: { column: "" } },
                                                      [
                                                        _c(
                                                          "v-expansion-panels",
                                                          {
                                                            attrs: {
                                                              multiple: "",
                                                              flat: "",
                                                              value:
                                                                _vm.selectedGoodsServicesOpenedPanels,
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.groupedSelectedGoodsServices,
                                                            function (
                                                              group,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "v-expansion-panel",
                                                                { key: index },
                                                                [
                                                                  group.niceClass
                                                                    ? _c(
                                                                        "v-expansion-panel-header",
                                                                        {
                                                                          staticClass:
                                                                            "py-0",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item",
                                                                            {
                                                                              staticClass:
                                                                                "px-0",
                                                                              attrs:
                                                                                {
                                                                                  dense:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-1",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "justify-center",
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$braendz.getNiceClassIcon(
                                                                                            group
                                                                                              .niceClass
                                                                                              .key
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-list-item-content",
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-title",
                                                                                    {
                                                                                      staticClass:
                                                                                        "font-weight-medium",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            group
                                                                                              .niceClass
                                                                                              .shortDescription
                                                                                          ) +
                                                                                          " - " +
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "createBrand.classification.niceClass"
                                                                                            )
                                                                                          ) +
                                                                                          " " +
                                                                                          _vm._s(
                                                                                            group
                                                                                              .niceClass
                                                                                              .key
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "v-expansion-panel-content",
                                                                    _vm._l(
                                                                      group.goodsServices,
                                                                      function (
                                                                        goodOrService,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "v-chip",
                                                                          {
                                                                            key: index,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "",
                                                                                close:
                                                                                  "",
                                                                                value:
                                                                                  goodOrService,
                                                                              },
                                                                            on: {
                                                                              "click:close":
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.removeSelectedGoodOrService(
                                                                                    goodOrService
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  goodOrService.description
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-center",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.classification.tabManualSelection.emptyList"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "d-flex justify-center",
                                              },
                                              [
                                                _c("AddGoodOrServicePopup", {
                                                  on: {
                                                    added: _vm.addGoodOrService,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    plain: "",
                                                                  },
                                                                },
                                                                "v-btn",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fa-light fa-plus"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "createBrand.classification.tabManualSelection.buttonAdd"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                  model: {
                                                    value:
                                                      _vm.showAddGoodOrServicePopup,
                                                    callback: function ($$v) {
                                                      _vm.showAddGoodOrServicePopup =
                                                        $$v
                                                    },
                                                    expression:
                                                      "showAddGoodOrServicePopup",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          { staticClass: "d-flex flex-column align-center" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-2",
                                attrs: {
                                  width: "300",
                                  dark: _vm.selectedGoodsServices.length > 0,
                                  color: "tertiary",
                                  disabled:
                                    _vm.selectedGoodsServices.length === 0,
                                },
                                on: { click: _vm.updateResults },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v(" fa-light fa-play "),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "createBrand.buttonShowSummaryTitle"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step >= 3,
                    expression: "step >= 3",
                  },
                ],
                attrs: { "data-aos": "fade-down" },
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-container",
                      { staticClass: "pa-0", attrs: { fluid: "" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                attrs: {
                                  xl: "3",
                                  lg: "4",
                                  md: "6",
                                  sm: "12",
                                  cols: "12",
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "fill-height",
                                    attrs: { elevation: "1", rounded: "" },
                                  },
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v(
                                            "fa-light fa-memo-circle-info"
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "createBrand.configuredBrand.title"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-card-subtitle", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "createBrand.configuredBrand.subtitle"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "fill-height",
                                            attrs: {
                                              light: "",
                                              elevation: "3",
                                              rounded: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticStyle: {
                                                  display: "block",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item",
                                                  { attrs: { "two-line": "" } },
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _vm.selectedBrandType &&
                                                        _vm.selectedBrandType
                                                          .key !== "Figurative"
                                                          ? _c(
                                                              "TruncateTooltip",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    _vm.name,
                                                                  top: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "text-h5 text-truncate",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "v-list-item-title",
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "$no-text"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                        _vm.selectedBrandType &&
                                                        _vm.selectedBrandType
                                                          .description
                                                          ? _c(
                                                              "v-list-item-subtitle",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .selectedBrandType
                                                                        .description
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm.selectedBrandType
                                                          ? _c(
                                                              "v-list-item-subtitle",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .selectedBrandType
                                                                        .key
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "v-list-item-subtitle",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "brand.tile.valueNotAvailable"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("v-divider", {
                                              staticClass: "mx-3",
                                            }),
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "BrandDetailsTextField",
                                                          {
                                                            staticClass: "pa-3",
                                                            attrs: {
                                                              title: _vm.$t(
                                                                "createBrand.configuredBrand.countries"
                                                              ),
                                                            },
                                                          },
                                                          [
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "ma-0",
                                                                  attrs: {
                                                                    row: "",
                                                                    "justify-start":
                                                                      "",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  _vm.selectedRegistrationOfficeCodes,
                                                                  function (
                                                                    registrationOfficeCode,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "v-flex",
                                                                      {
                                                                        key: index,
                                                                        staticClass:
                                                                          "mr-2",
                                                                        attrs: {
                                                                          shrink:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-avatar",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                tile: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            registrationOfficeCode.description
                                                                              ? _c(
                                                                                  "v-tooltip",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        bottom:
                                                                                          "",
                                                                                      },
                                                                                    scopedSlots:
                                                                                      _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key: "activator",
                                                                                            fn: function (
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                registrationOfficeCode.key
                                                                                                  ? _c(
                                                                                                      "v-img",
                                                                                                      _vm._g(
                                                                                                        _vm._b(
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                src: _vm.$braendz.registrationOfficeCodeFlag(
                                                                                                                  registrationOfficeCode.key
                                                                                                                ),
                                                                                                                contain:
                                                                                                                  "",
                                                                                                                "max-height":
                                                                                                                  "50",
                                                                                                                "max-width":
                                                                                                                  "50",
                                                                                                              },
                                                                                                          },
                                                                                                          "v-img",
                                                                                                          attrs,
                                                                                                          false
                                                                                                        ),
                                                                                                        on
                                                                                                      )
                                                                                                    )
                                                                                                  : _vm._e(),
                                                                                              ]
                                                                                            },
                                                                                          },
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      ),
                                                                                  },
                                                                                  [
                                                                                    registrationOfficeCode
                                                                                      ? _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                registrationOfficeCode.description
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ]
                                                                                )
                                                                              : registrationOfficeCode.key
                                                                              ? _c(
                                                                                  "v-img",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        src: _vm.$braendz.registrationOfficeCodeFlag(
                                                                                          registrationOfficeCode.key
                                                                                        ),
                                                                                        contain:
                                                                                          "",
                                                                                        "max-height":
                                                                                          "50",
                                                                                        "max-width":
                                                                                          "50",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("v-divider", {
                                              staticClass: "mx-3",
                                            }),
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticStyle: {
                                                      height: "150px",
                                                    },
                                                    attrs: { align: "center" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-center",
                                                        attrs: { cols: "12" },
                                                      },
                                                      [
                                                        !_vm.noLogo && _vm.logo
                                                          ? _c("v-img", {
                                                              attrs: {
                                                                src: _vm.logoUrl,
                                                                contain: "",
                                                                "max-height":
                                                                  "100",
                                                              },
                                                            })
                                                          : _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  size: "100",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa-light fa-image-slash"
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("v-divider", {
                                              staticClass: "mb-2 mx-3",
                                            }),
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c(
                                                          "BrandDetailsTextField",
                                                          {
                                                            staticClass: "pa-3",
                                                            attrs: {
                                                              title: _vm.$t(
                                                                "createBrand.configuredBrand.goodsAndServices"
                                                              ),
                                                              titleTop: true,
                                                            },
                                                          },
                                                          [
                                                            [
                                                              _c(
                                                                "v-list",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "transparent",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  _vm.groupedSelectedGoodsServices,
                                                                  function (
                                                                    goodsServicesGroup,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "v-list-item",
                                                                      {
                                                                        key: index,
                                                                      },
                                                                      [
                                                                        goodsServicesGroup.niceClass
                                                                          ? _c(
                                                                              "v-list-item-avatar",
                                                                              {
                                                                                staticClass:
                                                                                  "align-self-start",
                                                                              },
                                                                              [
                                                                                goodsServicesGroup
                                                                                  .niceClass
                                                                                  .description
                                                                                  ? _c(
                                                                                      "v-tooltip",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            bottom:
                                                                                              "",
                                                                                          },
                                                                                        scopedSlots:
                                                                                          _vm._u(
                                                                                            [
                                                                                              {
                                                                                                key: "activator",
                                                                                                fn: function (
                                                                                                  ref
                                                                                                ) {
                                                                                                  var on =
                                                                                                    ref.on
                                                                                                  var attrs =
                                                                                                    ref.attrs
                                                                                                  return [
                                                                                                    goodsServicesGroup.niceClass
                                                                                                      ? _c(
                                                                                                          "v-chip",
                                                                                                          _vm._g(
                                                                                                            _vm._b(
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    small:
                                                                                                                      "",
                                                                                                                    outlined:
                                                                                                                      "",
                                                                                                                  },
                                                                                                              },
                                                                                                              "v-chip",
                                                                                                              attrs,
                                                                                                              false
                                                                                                            ),
                                                                                                            on
                                                                                                          ),
                                                                                                          [
                                                                                                            _c(
                                                                                                              "span",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "body-1",
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  _vm._s(
                                                                                                                    goodsServicesGroup
                                                                                                                      .niceClass
                                                                                                                      .key
                                                                                                                  )
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ]
                                                                                                        )
                                                                                                      : _vm._e(),
                                                                                                  ]
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                            null,
                                                                                            true
                                                                                          ),
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                goodsServicesGroup
                                                                                                  .niceClass
                                                                                                  .description
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _c(
                                                                                      "v-chip",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            small:
                                                                                              "",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "body-1",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                goodsServicesGroup
                                                                                                  .niceClass
                                                                                                  .key
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "v-list-item-content",
                                                                          [
                                                                            goodsServicesGroup
                                                                              .goodsServices
                                                                              .length >
                                                                            0
                                                                              ? _c(
                                                                                  "v-list-item-title",
                                                                                  {
                                                                                    staticClass:
                                                                                      "wrap-text",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          goodsServicesGroup.goodsServices
                                                                                            .map(
                                                                                              function (
                                                                                                gs
                                                                                              ) {
                                                                                                return gs.description
                                                                                              }
                                                                                            )
                                                                                            .join(
                                                                                              "; "
                                                                                            )
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "v-list-item-title",
                                                                                  {
                                                                                    staticClass:
                                                                                      "font-italic text--disabled",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "brand.tile.valueNotAvailable"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-column",
                                attrs: {
                                  xl: "6",
                                  lg: "4",
                                  md: "6",
                                  sm: "12",
                                  cols: "12",
                                },
                              },
                              [
                                _vm.aiEvaluationBrandName.object ||
                                _vm.aiEvaluationBrandName.isBusy
                                  ? _c(
                                      "v-row",
                                      { staticClass: "flex-grow-1" },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "fill-height d-flex flex-column",
                                                attrs: {
                                                  elevation: "1",
                                                  rounded: "",
                                                  loading:
                                                    _vm.aiEvaluationBrandName
                                                      .isBusy,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card-title",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          color:
                                                            "amber darken-2",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-sparkles"
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.aiEvaluationBrandName.title"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c("v-card-subtitle", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createBrand.aiEvaluationBrandName.subtitle",
                                                          { name: _vm.name }
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                !_vm.aiEvaluationBrandName
                                                  .isBusy &&
                                                _vm.aiEvaluationBrandName
                                                  .object &&
                                                _vm.aiEvaluationBrandName.object
                                                  .content
                                                  ? _c(
                                                      "v-card-text",
                                                      _vm._l(
                                                        _vm
                                                          .aiEvaluationBrandName
                                                          .object.content,
                                                        function (
                                                          content,
                                                          index
                                                        ) {
                                                          return _c("div", {
                                                            key: index,
                                                            staticClass:
                                                              "completion-summary",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                content.text
                                                              ),
                                                            },
                                                          })
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm.aiEvaluationBrandName
                                                      .isBusy
                                                  ? _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "flex-grow-1 align-content-center text-center",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "createBrand.aiEvaluationBrandName.loading"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.aiEvaluationRegistrationOfficeCodes
                                  .object ||
                                _vm.aiEvaluationRegistrationOfficeCodes.isBusy
                                  ? _c(
                                      "v-row",
                                      { staticClass: "flex-grow-1" },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "fill-height d-flex flex-column",
                                                attrs: {
                                                  elevation: "1",
                                                  rounded: "",
                                                  loading:
                                                    _vm
                                                      .aiEvaluationRegistrationOfficeCodes
                                                      .isBusy,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card-title",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          color:
                                                            "amber darken-2",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-sparkles"
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.aiEvaluationCountries.title"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c("v-card-subtitle", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createBrand.aiEvaluationCountries.subtitle"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                !_vm
                                                  .aiEvaluationRegistrationOfficeCodes
                                                  .isBusy &&
                                                _vm
                                                  .aiEvaluationRegistrationOfficeCodes
                                                  .object &&
                                                _vm
                                                  .aiEvaluationRegistrationOfficeCodes
                                                  .object.content
                                                  ? _c(
                                                      "v-card-text",
                                                      _vm._l(
                                                        _vm
                                                          .aiEvaluationRegistrationOfficeCodes
                                                          .object.content,
                                                        function (
                                                          content,
                                                          index
                                                        ) {
                                                          return _c("div", {
                                                            key: index,
                                                            staticClass:
                                                              "completion-summary",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                content.text
                                                              ),
                                                            },
                                                          })
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm
                                                      .aiEvaluationRegistrationOfficeCodes
                                                      .isBusy
                                                  ? _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "flex-grow-1 align-content-center text-center",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "createBrand.aiEvaluationCountries.loading"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.aiEvaluationGoodsServices.object ||
                                _vm.aiEvaluationGoodsServices.isBusy
                                  ? _c(
                                      "v-row",
                                      { staticClass: "flex-grow-1" },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "fill-height d-flex flex-column",
                                                attrs: {
                                                  elevation: "1",
                                                  rounded: "",
                                                  loading:
                                                    _vm
                                                      .aiEvaluationGoodsServices
                                                      .isBusy,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card-title",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          color:
                                                            "amber darken-2",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-sparkles"
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.aiEvaluationGoodsAndServices.title"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c("v-card-subtitle", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createBrand.aiEvaluationGoodsAndServices.subtitle"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                !_vm.aiEvaluationGoodsServices
                                                  .isBusy &&
                                                _vm.aiEvaluationGoodsServices
                                                  .object &&
                                                _vm.aiEvaluationGoodsServices
                                                  .object.content
                                                  ? _c(
                                                      "v-card-text",
                                                      _vm._l(
                                                        _vm
                                                          .aiEvaluationGoodsServices
                                                          .object.content,
                                                        function (
                                                          content,
                                                          index
                                                        ) {
                                                          return _c("div", {
                                                            key: index,
                                                            staticClass:
                                                              "completion-summary",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                content.text
                                                              ),
                                                            },
                                                          })
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm
                                                      .aiEvaluationGoodsServices
                                                      .isBusy
                                                  ? _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "flex-grow-1 align-content-center text-center",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "createBrand.aiEvaluationGoodsAndServices.loading"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.domainResults.list.length > 0 ||
                            _vm.domainResults.isBusy
                              ? _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      xl: "3",
                                      lg: "4",
                                      md: "12",
                                      sm: "12",
                                      cols: "12",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "fill-height d-flex flex-column",
                                        attrs: {
                                          elevation: "1",
                                          rounded: "",
                                          loading: _vm.domainResults.isBusy,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  left: "",
                                                  color: "primary",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "fa-light fa-globe-pointer"
                                                ),
                                              ]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "createBrand.domains.title"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("v-card-subtitle", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "createBrand.domains.subtitle"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        !_vm.domainResults.isBusy &&
                                        _vm.domainResults.list.length > 0
                                          ? _c(
                                              "v-card-text",
                                              _vm._l(
                                                _vm.domainResults.list,
                                                function (domain, index) {
                                                  return _c("DomainChip", {
                                                    key: index,
                                                    staticClass: "ma-2",
                                                    attrs: {
                                                      available:
                                                        domain.available,
                                                      domain: domain.name,
                                                      premium: domain.premium,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm.domainResults.isBusy
                                          ? _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "flex-grow-1 align-content-center text-center",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createBrand.domains.searching"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "fill-height",
                                    attrs: {
                                      elevation: "1",
                                      rounded: "",
                                      loading: _vm.brandQueryResult.isBusy,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$braendz.getBrandTypeIcon(
                                                "Combined"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "createBrand.brandQueryResults.title"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-card-subtitle", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "createBrand.brandQueryResults.subtitle"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-container",
                                          { attrs: { fluid: "" } },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-toolbar",
                                                      {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          flat: "",
                                                          dense: "",
                                                          color: "transparent",
                                                        },
                                                      },
                                                      [
                                                        _c("v-spacer"),
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              left: "",
                                                              "close-on-content-click": false,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  icon: "",
                                                                                },
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "fa-light fa-gears"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "v-list",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "transparent",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-action",
                                                                      [
                                                                        _c(
                                                                          "v-switch",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                inset:
                                                                                  "",
                                                                                flat: "",
                                                                                disabled:
                                                                                  _vm
                                                                                    .brandQueryResult
                                                                                    .isBusy,
                                                                                "hide-details":
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                _vm.updateBrandQueryResult,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.enableNiceClassFilter,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.enableNiceClassFilter =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "enableNiceClassFilter",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "createBrand.brandQueryResults.enableNiceClassFilter"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-action",
                                                                      [
                                                                        _c(
                                                                          "ExplainButton",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: true,
                                                                                color:
                                                                                  "amber darken-2",
                                                                                chatInstance:
                                                                                  _vm.chatInstance,
                                                                                message:
                                                                                  _vm.$t(
                                                                                    "createBrand.brandQueryResults.enableNiceClassFilterExplainMessage"
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              posted:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.chatOpened = true
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.brandQueryResult
                                                      .object &&
                                                    _vm.brandQueryResult.object
                                                      .items &&
                                                    _vm.brandQueryResult.object
                                                      .items.length > 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass: "pb-2",
                                                          },
                                                          [
                                                            _c(
                                                              "VerticalBrandTileGrid",
                                                              {
                                                                attrs: {
                                                                  brands:
                                                                    _vm
                                                                      .brandQueryResult
                                                                      .object
                                                                      .items,
                                                                  infoMode:
                                                                    "Minimum",
                                                                  showGoodsServices: true,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "mainaction",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var brand =
                                                                            ref.brand
                                                                          return [
                                                                            _vm._t(
                                                                              "mainaction",
                                                                              function () {
                                                                                return [
                                                                                  _c(
                                                                                    "ExplainConflictButton",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          title:
                                                                                            "Test",
                                                                                          message:
                                                                                            "Test",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              {
                                                                                brand:
                                                                                  brand,
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fill-height",
                                                            staticStyle: {
                                                              "padding-top":
                                                                "100px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-center",
                                                                staticStyle: {
                                                                  position:
                                                                    "sticky",
                                                                  top: "50%",
                                                                  transform:
                                                                    "translateY(-50%)",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "16px",
                                                                        "font-weight":
                                                                          "900",
                                                                      },
                                                                    attrs: {
                                                                      color:
                                                                        "tertiary",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fa-light fa-face-thinking"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "tertiary--text",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "16px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "createBrand.brandQueryResults.noBrandFound"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("ChatAside", {
          attrs: {
            instance: _vm.chatInstance,
            position: _vm.$vuetify.breakpoint.lgAndUp ? "sticky" : "fixed",
            elevation: 1,
            "max-width": _vm.$vuetify.breakpoint.lgAndUp
              ? "25%"
              : _vm.$vuetify.breakpoint.md
              ? "50%"
              : "100%",
          },
          model: {
            value: _vm.chatOpened,
            callback: function ($$v) {
              _vm.chatOpened = $$v
            },
            expression: "chatOpened",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }