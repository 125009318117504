var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { scrollable: "", "max-width": "800" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.$t("addGoodOrServicePopup.title"))),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-6 pb-0" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { light: "" },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.niceClasses,
                                          label: _vm.$t(
                                            "addGoodOrServicePopup.selectNiceClassLabel"
                                          ),
                                          required: "",
                                          rules: [_vm.$validation.required],
                                          dense: "",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "selection",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.key) +
                                                    " - " +
                                                    _vm._s(
                                                      data.item.shortDescription
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                typeof data.item !== "object"
                                                  ? [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _vm._v(
                                                            _vm._s(data.item)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : [
                                                      _c(
                                                        "v-list-item-icon",
                                                        { staticClass: "mr-3" },
                                                        [
                                                          data.item.key
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$braendz.getNiceClassIcon(
                                                                          data
                                                                            .item
                                                                            .key
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .key
                                                                  ) +
                                                                  " - " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .shortDescription
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            {
                                                              staticClass:
                                                                "wrap-text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .description
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.selectedNiceClass,
                                          callback: function ($$v) {
                                            _vm.selectedNiceClass = $$v
                                          },
                                          expression: "selectedNiceClass",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "addGoodOrServicePopup.textFieldGoodOrServiceLabel"
                                          ),
                                          required: "",
                                          rules: [_vm.$validation.required],
                                        },
                                        model: {
                                          value: _vm.goodOrServiceDescription,
                                          callback: function ($$v) {
                                            _vm.goodOrServiceDescription = $$v
                                          },
                                          expression:
                                            "goodOrServiceDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "", disabled: !_vm.valid },
                          on: { click: _vm.add },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("addGoodOrServicePopup.buttonAdd"))
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("addGoodOrServicePopup.buttonCancel"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }