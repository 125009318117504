import { render, staticRenderFns } from "./ExplainButton.vue?vue&type=template&id=4ebc3378&scoped=true&"
import script from "./ExplainButton.vue?vue&type=script&lang=ts&"
export * from "./ExplainButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ebc3378",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/source/client-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ebc3378')) {
      api.createRecord('4ebc3378', component.options)
    } else {
      api.reload('4ebc3378', component.options)
    }
    module.hot.accept("./ExplainButton.vue?vue&type=template&id=4ebc3378&scoped=true&", function () {
      api.rerender('4ebc3378', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Assistant/ExplainButton.vue"
export default component.exports