var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: {
        color: _vm.tooltipColor,
        bottom: _vm.bottom,
        top: _vm.top,
        left: _vm.left,
        right: _vm.right,
        disabled: _vm.disabled,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              return [
                _c(
                  "v-chip",
                  _vm._g(
                    _vm._b(
                      {
                        class: _vm.chipClass,
                        on: {
                          click: [
                            _vm.onChipClicked,
                            function ($event) {
                              $event.stopPropagation()
                            },
                          ],
                        },
                      },
                      "v-chip",
                      _vm.$attrs,
                      false
                    ),
                    on
                  ),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _vm.tooltip ? [_c("span", [_vm._v(_vm._s(_vm.tooltip))])] : _vm._e(),
      _vm.$slots.tooltip ? [_vm._t("tooltip")] : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }