var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c(
        "v-tooltip",
        {
          attrs: { bottom: "", color: _vm.brandStateCategoryColor },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _vm.brandStateCategoryIcon
                      ? _c(
                          "v-icon",
                          _vm._g(
                            _vm._b(
                              {
                                class: { baseline: _vm.baseline },
                                attrs: { color: _vm.brandStateCategoryColor },
                              },
                              "v-icon",
                              _vm.$attrs,
                              false
                            ),
                            on
                          ),
                          [_vm._v(_vm._s(_vm.brandStateCategoryIcon))]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ],
            null,
            false,
            470361691
          ),
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.value.shortDescription
                  ? _vm.value.shortDescription
                  : _vm.value.key
              )
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }